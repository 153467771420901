/** @jsxImportSource @emotion/react */
'use client';
import { FC } from 'react';
import 'twin.macro';
export const Loading: FC = () => {
  return (
    <main tw="w-full h-full flex items-center justify-center">
      <span tw="loading loading-spinner loading-lg"></span>
    </main>
  );
};
export default Loading;
